// Gallery.js
import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Gallery() {
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://api.unsplash.com/photos/random?count=100&page=${page}&client_id=${"KfkAVWhOcEARRzTqmec03SVLy_7gbXMcbogXl_X18aE"}`
      )
      .then((response) => {
        setPhotos([...photos, ...response.data]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [page]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      setPage(page + 1);
    }
  };
  var imageUrls = [
    "https://i.ibb.co/w4vtZnW/IMG-0346.jpg",
    "https://i.ibb.co/txWBLgY/IMG-1065.jpg",
    "https://i.ibb.co/wYF431x/IMG-1399.jpg",
    "https://i.ibb.co/8YTFJzF/IMG-1401.jpg",
    "https://i.ibb.co/X7vVkqN/IMG-1403.jpg",
    "https://i.ibb.co/XX0LYd4/IMG-1407.jpg",
    "https://i.ibb.co/vYMHBFM/IMG-1948.jpg",
    "https://i.ibb.co/Xy8QmT5/IMG-1949.jpg",
    "https://i.ibb.co/3d4DXXX/IMG-2549.jpg",
    "https://i.ibb.co/5MB8Ncs/IMG-4569.jpg",
    "https://i.ibb.co/CKXK2YH/IMG-9581-1.jpg",
    "https://i.ibb.co/JHZ0hVw/PHOTO-2021-08-17-14-38-10.jpg",
    "https://i.ibb.co/Qd31yPC/PHOTO-2021-08-17-14-39-40.jpg",
    "https://i.ibb.co/HFJYngF/PHOTO-2021-09-11-10-08-35.jpg",
    "https://i.ibb.co/cgwNm0K/PHOTO-2021-11-02-13-00-37.jpg",
    "https://i.ibb.co/0BVrkqR/PHOTO-2021-12-11-11-29-05-4.jpg",
    "https://i.ibb.co/vwVNXBL/PHOTO-2022-01-22-12-20-25-4.jpg",
    "https://i.ibb.co/ZdX0mpb/PHOTO-2022-02-10-15-01-07.jpg",
    "https://i.ibb.co/FnJXVmR/PHOTO-2022-02-12-15-00-21.jpg",
    "https://i.ibb.co/y49gnG4/PHOTO-2022-02-16-14-55-51.jpg",
    "https://i.ibb.co/RC4g4jS/PHOTO-2022-12-02-10-15-26.jpg",
    "https://i.ibb.co/KVk98G4/PHOTO-2023-01-09-09-04-50.jpg",
    "https://i.ibb.co/KKBm3Sr/PHOTO-2023-02-08-15-53-37.jpg",
    "https://i.ibb.co/L83yRC1/PHOTO-2023-02-14-16-09-03-4.jpg",
    "https://i.ibb.co/BZ7Cg7L/PHOTO-2023-02-14-16-09-03.jpg",
    "https://i.ibb.co/z4b5SVy/PHOTO-2023-02-16-15-59-25.jpg",
    "https://i.ibb.co/n8k4hkW/PHOTO-2023-03-04-15-05-14.jpg",
    "https://i.ibb.co/4Fzt2t8/PHOTO-2023-03-14-17-06-06-2.jpg",
    "https://i.ibb.co/7zLNLS6/PHOTO-2023-03-15-02-24-01-2.jpg",
    "https://i.ibb.co/1sq7bTs/PHOTO-2023-03-15-13-10-09.jpg",
    "https://i.ibb.co/GxrzMZ0/PHOTO-2023-03-15-13-10-10.jpg",
    "https://i.ibb.co/TW0D3nb/PHOTO-2023-03-31-19-53-54.jpg",
    "https://i.ibb.co/QnqpgW8/PHOTO-2023-04-01-13-23-27.jpg",
    "https://i.ibb.co/tzWNPq7/PHOTO-2023-04-05-18-35-12.jpg",
    "https://i.ibb.co/m5cM1vZ/PHOTO-2023-04-11-08-41-14.jpg",
    "https://i.ibb.co/252DFd2/PHOTO-2023-04-11-17-42-49.jpg",
    "https://i.ibb.co/Wz0b1y4/PHOTO-2023-04-13-13-21-59.jpg",
    "https://i.ibb.co/941tcrs/PHOTO-2023-05-06-13-53-32-3.jpg",
    "https://i.ibb.co/qkfYC3x/PHOTO-2023-05-10-13-58-08.jpg",
    "https://i.ibb.co/k6cN8wk/PHOTO-2023-05-12-17-09-21-4.jpg",
    "https://i.ibb.co/w6xGtTk/PHOTO-2023-05-12-17-09-21.jpg",
    "https://i.ibb.co/nstLR8L/PHOTO-2023-05-24-13-29-37.jpg",
    "https://i.ibb.co/X568SmZ/PHOTO-2023-05-25-18-14-07-4.jpg",
    "https://i.ibb.co/2YzhTjV/PHOTO-2023-06-03-13-56-11.jpg",
    "https://i.ibb.co/bgvtr36/PHOTO-2023-06-03-13-59-13.jpg",
    "https://i.ibb.co/gDZFxDc/PHOTO-2023-06-17-16-25-28.jpg",
    "https://i.ibb.co/TbQH5cC/PHOTO-2023-06-17-16-25-30.jpg",
    "https://i.ibb.co/WcZRG2n/PHOTO-2023-06-17-16-25-31.jpg",
    "https://i.ibb.co/yF1HPgw/PHOTO-2023-07-01-14-22-35.jpg",
    "https://i.ibb.co/sq9jfnq/PHOTO-2023-07-01-14-22-40.jpg",
  ];

  return (
    <div className="gallery" onScroll={handleScroll}>
      {imageUrls.map((url, index) => (
        <div
          key={index}
          className="gallery__item"
          style={{
            backgroundImage: `url(${url})`,
            height: `${Math.floor(Math.random() * 300) + 200}px`,
            width: `${Math.floor(window.innerWidth / 5)}px`,
          }}
        >
          <div className="gallery__item__overlay">
            {loading && <div className="gallery__item__loading"></div>}
          </div>
        </div>
      ))}
    </div>
  );
}
