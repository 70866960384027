import Counter from "./Counter";
import Service from "./Service";
import Why from "./Why";
import Clients from "./Clients";

export default function Home({ navCurrent, handleClick }) {
  return (
    <main>
      <Why />
      <Service />
      <Counter />
      <Clients />
    </main>
  );
}
