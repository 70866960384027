import React, { useState, useEffect } from "react";
import Header from "./Pages/Header";
import Footer from "./Pages/Footer";
import Nav from "./Components/Nav";
import Contact from "./Pages/Contact";

const navLink = [
  "Home",
  "Service",
  "Works",
  "Sustainability",
  "Contact us",
  "About us",
];

const Layout = ({ children, showHeader, showFooter, showNav }) => {
  const [scrollY, setScrollY] = useState(0);
  const [navCurrent, setNavCurrent] = useState(0);

  const handleClick = (key) => {
    setNavCurrent(key);
  };

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      {showNav && (
        <Nav
          links={navLink}
          scroll={scrollY}
          id={navCurrent}
          handleClick={handleClick}
          element={<Contact id={navCurrent} handleClick={handleClick} />}
        />
      )}
      {showHeader && <Header />}
      {children}
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
