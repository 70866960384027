import { useEffect, memo } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./styles/Main.scss";
import Layout from "./Layout";

import Contact from "./Pages/Contact";
import Sustainability from "./Pages/Sustainability";
import About from "./Pages/About";
import Works from "./Pages/Works";
import Home from "./Pages/Home";
import Gallery from "./Pages/Gallery";

const routes = [
  {
    path: "/",
    component: Home,
    showNav: true,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/Home",
    component: Home,
    showNav: true,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/Sustainability",
    component: Sustainability,
    showNav: true,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/Works",
    component: Works,
    showNav: true,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/Service",
    component: Home,
    showNav: true,
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/Contactus",
    component: Contact,
    showNav: true,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/Aboutus",
    component: About,
    showNav: true,
    showHeader: false,
    showFooter: true,
  },
  {
    path: "/Gallery",
    component: Gallery,
    showNav: true,
    showHeader: false,
    showFooter: false,
  },
];

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname === "/Service") {
      const serviceSection = document.getElementById("Service");
      if (serviceSection) {
        serviceSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <MemoizedLayout
                showNav={route.showNav}
                showHeader={route.showHeader}
                showFooter={route.showFooter}
              >
                <route.component />
              </MemoizedLayout>
            }
          />
        ))}
      </Routes>
    </>
  );
};

// Memoize the Layout component to prevent unnecessary re-renders.
const MemoizedLayout = memo(Layout);

export default App;
