import style from "./Sustainability.module.css";
export default function About() {
  return (
    <div className={`${style.sustainability} ${style.about__bg}`}>
      <div className={style.sustainability__content}>
        <h1>About us</h1>
        <p>
          Discover Bute Construction, your trusted partner for transformative
          construction solutions. Based in the vibrant heart of London, we bring
          over three years of dedicated service to the table, by being a dynamic
          and experienced leader in the realm of highway maintenance, surfacing,
          ground works, and external projects. Our commitment lies in delivering
          eco-friendly practices, strategic planning, and cost-effective
          excellence that aligns seamlessly with your needs.
        </p>
        <h1>Our Vision</h1>
        <h4>-Pioneering Sustainable Transformation-</h4>
        <p>
          At Bute Construction, we envision a future where roads are not just
          paths to travel, but symbols of progress, reliability, and innovation.
          Our focus is on trailblazing eco-friendly practices, redefining
          surfacing materials, and reshaping construction norms. By merging
          strategic planning with innovative solutions, we strive to be at the
          forefront in creating a greener, more resilient environment.
        </p>
        <h1>Our Mission</h1>
        <h4>-Crafting Excellence, Building Partnerships- </h4>
        <p>
          Our mission is deeply rooted in our passion for craftsmanship and
          excellence. We're more than constructors; we're creators of
          transformative experiences. Whether it's resurfacing highways or
          sculpting external spaces, our dedication knows no bounds. Bute
          Construction thrives on values of integrity, transparency, and
          dedication. We don't just deliver projects; we build partnerships,
          ensuring your vision finds its form in our work.
        </p>
        <h1>Our Values</h1>
        <h4> Integrity</h4>
        <p>
          We uphold the highest ethical standards, fostering trust and
          transparency in all interactions.
        </p>
        <h4>Innovation</h4>
        <p>
          We believe in pushing boundaries. Our innovative spirit drives us to
          adopt groundbreaking methods and technologies that revolutionize
          construction.
        </p>
        <h4>Collaboration</h4>
        <p>
          Together, we're stronger. We collaborate with you, transforming your
          vision into reality through open dialogue and shared goals.
        </p>
        <h4> Quality</h4>
        <p>
          Excellence is our hallmark. We set high standards for ourselves,
          delivering work that speaks of our dedication to quality.
        </p>
        <h4> Sustainability</h4>
        <p>
          Eco-conscious practices are at our core, driving us to create
          infrastructure that safeguards our planet's future.
        </p>
        <h4>Client-Centric</h4>
        <p>
          You're at the heart of everything we do. Your needs steer our journey,
          and we tailor solutions to suit your unique requirements.
        </p>
      </div>
    </div>
  );
}
