export default function Input({
  type,
  placeholder,
  value,
  onChange,
  pattern,
  name,
  required,
}) {
  return (
    <div className="form__group">
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        className="form__input"
        value={value}
        pattern={pattern}
        onChange={onChange}
        required={required}
      />
      <label htmlFor="fullname" className="form__label">
        {placeholder}
      </label>
    </div>
  );
}
