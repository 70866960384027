import React, { useState, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import tar_2 from "../img/s_3.jpeg";
import highwall from "../img/highwall-min.jpeg";
import tar from "../img/s_2.jpeg";
import w_5 from "../img/s_1.jpeg";
import ww from "../img/ww.png";
import logo from "../img/log.png";

const Header = () => {
  const [currentWallItemIndex, setCurrentWallItemIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const wallItems = useMemo(
    () => [
      {
        image: ww,
        heading: "Bute construction",
        subheading: "Building with Vision, Quality & Pride",
      },
      {
        image: tar,
        heading: "Civil Engineering",
        subheading: "HIGHWAY MAINTENANCE & SURFACING EXPERTS",
      },
      {
        image: highwall,
        heading: "Bute construction",
        subheading: "A REPUTATION BUILT OVER TIME",
      },
      {
        image: w_5,
        heading: "Expectation Exceeded",
        subheading: "SUSTAINABLE SOLUTIONS TAILORED TO YOUR NEEDS",
      },
      {
        image: tar_2,
        heading: "Sustainable Solutions",
        subheading: "CRAFTING EXCELLENCE, BUILDING PARTNERSHIPS",
      },
    ],
    []
  );

  useEffect(() => {
    const newIntervalId = setInterval(() => {
      setIsSliding(true);
      setTimeout(() => {
        setCurrentWallItemIndex((prevIndex) =>
          prevIndex === wallItems.length - 1 ? 0 : prevIndex + 1
        );
        setIsSliding(false);
      }, 2000);
    }, 10000);

    setIntervalId(newIntervalId);

    return () => clearInterval(newIntervalId);
  }, [wallItems]);

  const currentWallItem = wallItems[currentWallItemIndex];

  const headingStyles = {
    transition: "opacity 1s ease-in-out",
    opacity: isSliding ? 0 : 1,
  };

  const subheadingStyles = {
    transition: "opacity 1s ease-in-out",
    opacity: isSliding ? 0 : 1,
  };

  const containerStyles = {
    overflow: "hidden",
    backgroundImage: `url(${currentWallItem.image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    transition: "background-image 1s ease-in-out",
  };
  const overlayStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    animation: "fadeIn 2s ease-in-out",
  };

  const imgStyles = {
    width: "20vw",
    height: "20vh",
    zIndex: 1,
  };

  const keyframes = `
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;

  const handlePrevClick = () => {
    clearInterval(intervalId);
    setIsSliding(true);
    setTimeout(() => {
      setCurrentWallItemIndex((prevIndex) =>
        prevIndex === 0 ? wallItems.length - 1 : prevIndex - 1
      );
      setIsSliding(false);
      const newIntervalId = setInterval(() => {
        setIsSliding(true);
        setTimeout(() => {
          setCurrentWallItemIndex((prevIndex) =>
            prevIndex === wallItems.length - 1 ? 0 : prevIndex + 1
          );
          setIsSliding(false);
        }, 2000);
      }, 10000);
      setIntervalId(newIntervalId);
    }, 2000);
  };

  const handleNextClick = () => {
    clearInterval(intervalId);
    setIsSliding(true);
    setTimeout(() => {
      setCurrentWallItemIndex((prevIndex) =>
        prevIndex === wallItems.length - 1 ? 0 : prevIndex + 1
      );
      setIsSliding(false);
      const newIntervalId = setInterval(() => {
        setIsSliding(true);
        setTimeout(() => {
          setCurrentWallItemIndex((prevIndex) =>
            prevIndex === wallItems.length - 1 ? 0 : prevIndex + 1
          );
          setIsSliding(false);
        }, 2000);
      }, 10000);
      setIntervalId(newIntervalId);
    }, 2000);
  };

  return (
    <header className="header__container" id="Home">
      <style>{keyframes}</style>
      <section>
        <div className="wall__container" style={containerStyles}>
          <div style={overlayStyles}></div>
          <img
            alt="logo"
            style={imgStyles}
            src={logo}
            className="responsive__none"
          />
          <h1 style={headingStyles} className="wall__heading">
            {currentWallItem.heading}
          </h1>
          <h4 style={subheadingStyles} className="wall__subheading">
            {currentWallItem.subheading}
          </h4>
          <NavLink className="btn btn--white btn--animated" to="/Contactus">
            Contact us
          </NavLink>
          <div className="wall__arrows">
            <button
              className="wall__arrow wall__arrow--right sus__button sus__button--right"
              onClick={handlePrevClick}
            ></button>
            <button
              className="wall__arrow wall__arrow--left sus__button sus__button--left"
              onClick={handleNextClick}
            ></button>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
