import React from "react";

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <h1 className="under-construction__heading">Under Construction</h1>
      <p className="under-construction__text">
        We're working hard to bring you an amazing website. Stay tuned!
      </p>
    </div>
  );
};

export default UnderConstruction;
