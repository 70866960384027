import style from "./Sustainability.module.css";
export default function Sustainability() {
  return (
    <div className={`${style.sustainability} ${style.sus__bg}`}>
      <div className={style.sustainability__content}>
        <h1>Sustainability</h1>
        <h4>-Embracing Sustainability for a Greener Tomorrow-</h4>
        <p>
          Environmental consciousness is a key focus for us at Bute
          Constructions. We make every effort to minimize our environmental
          impact in all our projects. We value the importance of eco-friendly
          practices while delivering top-quality and reliable services to our
          clients. Throughout our business, we prioritize sustainability,
          ensuring compliance with regulations while exceeding environmental
          goals. To achieve this, we work closely with our clients to adopt the
          most efficient and environmentally conscious methods of operation.
        </p>
        <h4>Environmental Stewardship</h4>
        <p>
          We are dedicated to minimizing our impact on air, water, land, and
          people, while positively contributing to biodiversity and cultural
          heritage. To ensure this, we have implemented an Environmental
          Management System accredited to ISO 14001:2015. We review and assess
          all our projects against the criteria of "reduce, reuse, recycle" to
          minimize waste.
        </p>
        <h4>Resource Efficiency</h4>
        <p>
          We believe in the efficient use of finite natural resources and
          actively promote the transition to a circular economy. This involves
          conserving water, reducing waste through design, and reusing and
          recovering materials whenever possible. We prioritize the use of
          recycled and secondary materials from other industries as raw
          materials for our products and fuels for our production processes. As
          a result, we consume more waste than we produce.
        </p>
        <h4>Recycling and Use of Recycled Materials</h4>
        <p>
          We maximize opportunities for recycling and reusing our waste, and we
          constantly seek innovative and proactive solutions in this field.
          Sending waste to landfill is an absolute last resort. Our current
          recycling figures indicate that 98% of all waste generated is
          recycled. For concrete mixing and aggregate production, we utilize
          high-quality recycled materials from our own sites. Additionally, our
          asphalt production follows a Waste and Resources Action Plan (WRAP) to
          minimize environmental impact. Through collaboration with our contacts
          and contracts, we ensure that materials no longer in use are
          repurposed elsewhere. If reuse is not possible or viable, we segregate
          and recycle these materials appropriately.
        </p>
        <h4>Climate Change and Energy Management</h4>
        <p>
          We recognize that our industry has a significant impact on natural
          resources, and with the growing concern for climate change and
          resource depletion, there is a pressing need to reduce our
          environmental footprint. So, we take a comprehensive approach to
          managing energy and greenhouse gas emissions throughout the life cycle
          of our operations. This includes considering the materials we use, our
          operations, transportation of products, their performance during use,
          and recycling or disposal at the end of their life. Our investments in
          energy and carbon-efficient technologies, waste-derived fuels,
          renewable energy sources, and responsible supply chain management help
          reduce the carbon footprint of our products.
        </p>
        <h4>Sustainable Procurement</h4>
        <p>
          We follow a "Sustainable Green Procurement Policy" and adhere to the
          Mayor for London's code for Green Procurement. Our priority is to
          purchase recyclable and environmentally friendly products, that meet
          the required standards and are financially feasible. Our staff follows
          Sustainable Green Procurement guidelines to make sustainable
          purchasing decisions.
        </p>
        <h4>Fleet & Transport</h4>
        <p>
          To reduce pollutant emissions such as Nitrous Oxides (NOx) and
          Particles over 10 microns (PM10), our entire diesel fleet uses Adblue.
          Adblue is an additive that reduces emissions through selective
          catalytic reduction. All our LGVs and plant equipment run on Ultra Low
          Sulphur Diesel (ULSD).
        </p>
        <h4>Visual Impact</h4>
        <p>
          We strive to keep the visual impact of our construction activities on
          the local environment to an absolute minimum. We keep on-site storage
          of construction materials to a minimum and only transport materials to
          the site as needed.
        </p>
        <h4>Public Impact</h4>
        <p>
          We actively communicate our work programs to local residents and
          businesses. This includes providing advance notifications of work,
          informing about the impact of our activities, and outlining the
          measures we will take to minimize any disruptions.
        </p>
      </div>
    </div>
  );
}
