import React, { useState } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { FiMapPin } from "react-icons/fi";
import Input from "../Components/Input";

export default function Contact() {
  const initialFormData = {
    fullname: "",
    email: "",
    number: "",
    query: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [btnState, setBtnState] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnState(true);
    try {
      const response = await axios.post(
        // "http://127.0.0.1:5001/bute-backend/us-central1/app/mail",
        "https://us-central1-bute-backend.cloudfunctions.net/app/mail",
        formData
      );
      toast.success("Successfully submitted!");
      // Reset the form data after successful submission
      setFormData(initialFormData);
      setBtnState(false);
    } catch (error) {
      toast.error("Invalid email address!");
      console.error(error);
      setFormData(initialFormData);
      setBtnState(false);
    }
  };

  return (
    <section className="contact">
      <main className={`contact__container u-margin-bottom-medium`}>
        <div className="contact__content">
          <div className="form__container">
            <Toaster
              position="top-center"
              reverseOrder={false}
              containerStyle={{
                fontSize: "2rem",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
            <form onSubmit={handleSubmit} className="form">
              <div className="form__heading">
                <h1 className="heading__primary u-margin-bottom-small">
                  contact us
                </h1>
              </div>
              <Input
                type="text"
                placeholder={"Full Name"}
                value={formData.fullname}
                onChange={handleChange}
                pattern="[A-Za-z]+"
                name={"fullname"}
                required={true}
              />
              <Input
                type="email"
                placeholder={"Email address"}
                value={formData.email}
                onChange={handleChange}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                name={"email"}
                required={true}
              />
              <Input
                type="text"
                placeholder={"Phone Number"}
                onChange={handleChange}
                pattern="[0-9+]+"
                name={"number"}
                value={formData.number}
                required={true}
              />

              <div className="form__group">
                <textarea
                  name="query"
                  placeholder="What would you like to discuss about ?"
                  className="form__input"
                  value={formData.query}
                  onChange={handleChange}
                  pattern="[A-Za-z]+"
                  required={true}
                />
                <label htmlFor="query" className="form__label">
                  What would you like to discuss about ?
                </label>
              </div>
              <button
                disabled={btnState}
                type="submit"
                className="btn btn__green"
              >
                {btnState ? (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </main>
      <div className="contact__header u-margin-bottom-medium">
        <div
          className="contact__header__content 
          "
        >
          <FiPhoneCall
            className="u-margin-bottom-small"
            style={{ width: "5rem", height: "5rem" }}
          />
          <h1 className="contact__header__heading u-margin-bottom-small">
            +44 7809 106913
          </h1>
        </div>
        <div className="contact__header__content">
          <HiOutlineMail
            className="u-margin-bottom-small"
            style={{ width: "5rem", height: "5rem" }}
          />
          <h1
            style={{ alignSelf: "center" }}
            className="contact__header__heading u-margin-bottom-small"
          >
            <a
              className="contact__header__link"
              href="mailto:info@buteconstruction.co.uk"
            >
              info@buteconstruction.co.uk
            </a>
            <br />
            <a
              className="contact__header__link"
              href="mailto:buteconstruction@outlook.com"
            >
              buteconstruction@outlook.com
            </a>
          </h1>
        </div>
        <div className="contact__header__content">
          <FiMapPin
            className="u-margin-bottom-small"
            style={{ width: "5rem", height: "5rem" }}
          />
          <h1 className="contact__header__heading u-margin-bottom-small">
            77 Vancouver Road, Edgware, Middlesex, HA8 5DG, UK
          </h1>
          <a href="#map" className="contact__header__subheading">
            MAP
          </a>
        </div>
      </div>
    </section>
  );
}
