import { useState, useEffect, useRef } from "react";

export default function Counter() {
  const [experience, setExperience] = useState(0);
  const [projects, setProjects] = useState(0);
  const [satisfaction, setSatisfaction] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const interval = setInterval(() => {
            setExperience((prevExperience) =>
              prevExperience < 3 ? prevExperience + 1 : prevExperience
            );
          }, 50);
          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const interval = setInterval(() => {
            setProjects((prevProjects) =>
              prevProjects < 15 ? prevProjects + 1 : prevProjects
            );
          }, 50);
          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const interval = setInterval(() => {
            setSatisfaction((prevSatisfaction) =>
              prevSatisfaction < 100 ? prevSatisfaction + 1 : prevSatisfaction
            );
          }, 50);
          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div className="counter__container" ref={ref}>
      <h1 className="counter__heading">
        Experience<span className="counter__span">{experience} yrs+</span>
      </h1>
      <h1 className="counter__heading">
        Projects<span className="counter__span">{projects}+</span>
      </h1>
      <h1 className="counter__heading">
        Customer satisfaction
        <span className="counter__span">{satisfaction}%</span>
      </h1>
    </div>
  );
}
