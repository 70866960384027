import one from "../img/clients/clients/1.png";
import two from "../img/clients/clients/2.png";
import three from "../img/clients/clients/3.jpg";
import four from "../img/clients/clients/4.gif";
import five from "../img/clients/clients/5.svg";
import six from "../img/clients/clients/6.png";

export default function Clients() {
  return (
    <main className="clients">
      <h1 className="heading__primary">our clients</h1>
      <section className="logos">
        <div className="logos-slide">
          <img className="clients__logo" src={one} alt="logo" />
          <img className="clients__logo" src={two} alt="logo" />
          <img className="clients__logo" src={three} alt="logo" />
          <img className="clients__logo" src={four} alt="logo" />
          <img className="clients__logo" src={five} alt="logo" />
          <img className="clients__logo" src={six} alt="logo" />
        </div>
        <div className="logos-slide">
          <img className="clients__logo" src={one} alt="logo" />
          <img className="clients__logo" src={two} alt="logo" />
          <img className="clients__logo" src={three} alt="logo" />
          <img className="clients__logo" src={four} alt="logo" />
          <img className="clients__logo" src={five} alt="logo" />
          <img className="clients__logo" src={six} alt="logo" />
        </div>
      </section>
    </main>
  );
}
