import { FiPhoneCall } from "react-icons/fi";
import { BsFillMapFill } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import footerLogo from "../img/elite.png";
import footerBadge from "../img/chas.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__links">
        <div style={{ display: "flex", gap: "2rem" }}>
          <img
            src={footerLogo}
            width={120}
            height={120}
            alt="logo"
            // className="footer__logo"
          />
          <Link
            to="https://www.chas.co.uk"
            target="_blank"
            className="footer__link"
          >
            <img height={120} src={footerBadge} alt="logo" />
          </Link>
        </div>
        <div className="footer__link__container">
          <div className="footer__link">
            <FiPhoneCall />
            +44 7809 106913
          </div>
          <div className="footer__link">VAT 345734879</div>
          <div className="footer__link">
            <BsFillMapFill />
            77 Vancouver Road, Edgware, Middlesex, HA8 5DG, UK
          </div>
        </div>
      </div>
      <div className="footer__quote">
        "Discover Bute Construction, your trusted partner for transformative
        construction solutions. Based in the vibrant heart of London, we bring
        over three years of dedicated service to the table"
        <br />
        <br />
        <br />
        <div className="footer__icons">
          <a
            href="https://www.facebook.com/profile.php?id=61550603377370"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF
              color="white"
              size="1.5em"
              style={{ backgroundColor: "black", margin: "10px" }}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/bute-construction-ltd"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn
              color="white"
              size="1.5em"
              style={{ backgroundColor: "black", margin: "10px" }}
            />
          </a>
        </div>
      </div>
      <div className="footer__map" id="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9912.182941695532!2d-0.29199015166471265!3d51.60405058083725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487616add86aec77%3A0x3b86caff77ec2b97!2s77%20Vancouver%20Rd%2C%20Edgware%20HA8%205DG%2C%20UK!5e0!3m2!1sen!2slk!4v1701660479727!5m2!1sen!2slk"
          width="250"
          height="250"
          style={{ border: 0, borderRadius: "10px" }}
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div
          style={{ color: "white", fontSize: "12px", marginTop: "12px" }}
          className=""
        >
          © Bute Construction LTD | Designed by &nbsp;
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://creativesquare.net/"
          >
            <span
              style={{
                transition: "color 0.3s",
                ":hover": { color: "rgba(255, 255, 255, 0.8)" },
              }}
            >
              creativesquare
            </span>
          </a>
          &nbsp; | All Rights Reserved
        </div>
      </div>
    </footer>
  );
}
