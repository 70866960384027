import { useState } from "react";
import Logo from "./logo";
import { NavLink, Link } from "react-router-dom";

export default function Nav({ links, id, handleClick, scroll, element }) {
  const [checked, setChecked] = useState(false);

  const handleCheck = (key) => {
    setChecked(!checked);
    handleClick(key);
  };

  return (
    <>
      <nav className={`header__nav`}>
        <ul className="header__ul">
          <li className="header__list">
            <Link className="header__link" to="/Home">
              <Logo
                logo="https://storage.googleapis.com/bute_images/logo_black.png"
                source={"header"}
              />
            </Link>
          </li>
          {links.map((link, key) => (
            <li className="header__list" key={key}>
              <NavLink
                to={`/${link.replace(" ", "")}`}
                onClick={() => handleClick(key)}
                className="header__link"
              >
                {link}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className={`navigation ${scroll < 100 && "responsive__none"}`}>
        <input
          type="checkbox"
          className="navigation__checkbox"
          id="navi-toggle"
          onClick={() => handleCheck(id)}
          checked={checked}
        />

        <label htmlFor="navi-toggle" className="navigation__button">
          <span className="navigation__icon">&nbsp;</span>
        </label>

        <div className="navigation__background">&nbsp;</div>

        <nav className="navigation__nav">
          <ul className="navigation__list">
            {links.map(
              (link, key) =>
                checked && (
                  <li className="navigation__item" key={key}>
                    <NavLink
                      onClick={() => handleCheck(key)}
                      to={`/${link.replace(" ", "")}`}
                      className="navigation__link"
                    >
                      <span>{key + 1}</span>
                      {link}
                    </NavLink>
                  </li>
                )
            )}
          </ul>
        </nav>
      </div>
    </>
  );
}
