import React, { useState, useMemo } from "react";
import util from "../img/icons/utilities.png";
import ground from "../img/icons/ground.png";
import highway from "../img/icons/highway.png";
import external from "../img/icons/external.png";
import utilCivil from "../img/icons/utilCivi.png";

const services = [
  {
    icon: util,
    title: "SURFACING",
    content: [
      "Our surfacing solutions cater to the varying requirements of our clients across residential, commercial, and industrial sectors, guaranteeing longevity and exceptional quality.",
      "Eco-friendly Surfacing Materials",
      "Strategical Plans",
      "Cost-effective layering",
    ],
  },
  {
    icon: ground,
    title: "GROUND WORKS",
    content: [
      "Delivering excellence in groundworks services for residential and commercial projects by providing a solid foundation.",
      "Foundation",
      "Drainage",
      "Retaining wall",
    ],
  },
  {
    icon: highway,
    title: "HIGHWAY MAINTENANCE",
    content: [
      "With expertise in a wide range of highway maintenance tasks, our skilled team excels in providing customized solutions.",
      "Routing Inspections",
      "Pothole Repairs",
      "Crack Sealing",
      "Resurfacing",
      "Full scale Rehabilitation",
    ],
  },
  {
    icon: external,
    title: "EXTERNAL WORKS",
    content: [
      "Providing high quality workmanship to ensure that the work is completed to perfection. We are proficient to perform diverse landscaping work.",
      "Driveways",
      "Paths & Patios",
      "New block paving",
      "Fencing",
      "Tarmac Layering",
      "Laying turf",
      "Garden Design",
    ],
  },
  {
    icon: utilCivil,
    title: "UTILITIES CIVIL WORKS",
    content: [
      "We ensure seamless functioning of vital utility services & maintaining the infrastructure integrity of urban environments.",
      "Underground ducting for utilities",
      "Chamber building and construction",
      "Concrete foundations for street furniture",
      "Cabinets",
      "Columns and Poles",
    ],
  },
];

const Why = React.memo(() => {
  const [showContent, setShowContent] = useState(
    new Array(services.length).fill(false)
  );

  const handleShow = (index) => {
    const newShowContent = [...showContent];
    newShowContent[index] = !newShowContent[index];
    setShowContent(newShowContent);
  };

  const memoizedServices = useMemo(() => services, []);

  return (
    <React.Fragment>
      <main className="why__container" id="Service">
        <h1 className="heading__primary" style={{ marginBottom: "4rem" }}>
          what we do
        </h1>
        <section className="why__content">
          <div className="why__card__container">
            {memoizedServices.map((service, index) => (
              <div key={index} className="why__card">
                <img
                  src={service.icon}
                  alt={`${service.title} logo`}
                  className="why__card__image"
                />
                <h1 className="why__card__heading">{service.title}</h1>
                {showContent[index] ? (
                  <p className="why__card__content u-margin-bottom-small">
                    {service.content[0]}
                  </p>
                ) : null}
                {!showContent[index] ? (
                  <ul>
                    {service.content.slice(1).map((item, itemIndex) => (
                      <li key={itemIndex} className="why__card__content">
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : null}
                <p
                  onClick={() => handleShow(index)}
                  className="why__card__toggle"
                >
                  {!showContent[index] ? "Show more >>" : "Show less >>"}
                </p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </React.Fragment>
  );
});

export default Why;
